import devtools from '@vue/devtools'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'



if (process.env.NODE_ENV === 'development') {
  devtools.connect(/* host, port */)
}

Vue.config.productionTip = false

import VueRippler from 'vue-rippler'
Vue.use(VueRippler)

import firebase from 'firebase/app'
import 'firebase/auth'; // for authentication
import 'firebase/storage'; // for storag
import 'firebase/firestore'; // for cloud firestore
import 'firebase/functions'; // for cloud functions

  // Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBPKkepwg9Uv_kqWpz1J4K2Lpdp210Ur7w",
  authDomain: "memobild-editor.firebaseapp.com",
  projectId: "memobild-editor",
  storageBucket: "memobild-editor.appspot.com",
  messagingSenderId: "418701505691",
  appId: "1:418701505691:web:d742db7ee8a3378e61423e",
  measurementId: "G-B967B3TKFF"
};
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);


new Vue({
  router,
  store,
  firebase,
  render: h => h(App)
}).$mount('#app')
